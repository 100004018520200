import { createColumnHelper } from '@tanstack/vue-table'
import type { IColumn, ITenantSchedule } from '@audit'
import type { MaybeRefOrGetter } from 'vue'
export const useScheduleColumns = (columns: MaybeRefOrGetter<IColumn[]>) => {
  const dayjs = useDayjs()
  const columnHelper = createColumnHelper<ITenantSchedule>()
  return computed(() =>
    toValue(columns)
      .filter(
        (col) =>
          !col.dataFieldName.toLowerCase().includes('leaseid') &&
          !col.dataFieldName.toLowerCase().includes('lease_id') &&
          !col.dataFieldName.toLowerCase().includes('leasename') &&
          !col.dataFieldName.toLowerCase().includes('lease_name'),
      )
      .map((col) =>
        columnHelper.accessor(col.dataFieldName, {
          header: col.displayName,
          cell: ({ getValue }) => {
            if (getValue()) {
              if (
                col.dataType === 'date' ||
                col.displayName.toLowerCase().includes('date')
              )
                return dayjs(getValue() as number).format('DD/MM/YYYY')
              if (col.dataType === 'number')
                return NumberToString.format(getValue() as number)
              if (col.dataType === 'currency')
                return USDollar.format(getValue() as number)
              return getValue()
            }
            return '-'
          },
        }),
      ),
  )
}
